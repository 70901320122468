import { Injectable, computed, inject, signal } from "@angular/core";
import { lastValueFrom } from "rxjs";

import { ModulesInterface } from "@common/ports/interfaces";

import { RolPermissionAbstraction } from "@roles-permissions/ports/abstractions";
import { ComponentsHelper, DataUserHelper } from "@common/domain/helpers";
import { LoadingAbstraction } from "@common/ports/abstractions";

@Injectable({
  providedIn: "root"
})
export class HomeService {
  private rolAbstraction = inject(RolPermissionAbstraction);
  private componentHelper = inject(ComponentsHelper);
  private dataUsertHelper = inject(DataUserHelper);

  private listModulesTemp = signal<ModulesInterface[]>([]);
  private listPermissionTemp = signal<ModulesInterface[]>([]);
  private listPermissionClientTemp = signal<ModulesInterface[]>([
    { id: '', code: 'recognition', name: 'Reconocimiento Facial', icon: 'microchip-ai', routerLink: '/main/recognition/home', children: [] },
    { id: '', code: 'orders', name: 'Pedidos', icon: 'clipboard', routerLink: '/main/orders/lis', children: [] },
    { id: '', code: 'profileClient', name: 'Perfil', icon: '', routerLink: '/main/profile/client', children: [] },
  ]);

  private listModuleClientTemp = signal<ModulesInterface[]>([
    { id: '', code: 'recognition', name: 'Reconocimiento Facial', icon: 'microchip-ai', routerLink: '/main/recognition/home', children: [] },
    { id: '', code: 'orders', name: 'Pedidos', icon: 'clipboard', routerLink: '/main/orders/list', children: [] },
  ]);

  public listModules = computed(() => this.listModulesTemp());
  public listPermission = computed(() => this.listPermissionTemp());
  public listPermissionClient = computed(() => this.listPermissionClientTemp());
  public listModuleClient = computed(() => this.listModuleClientTemp());

  getTyeUser() {
    return this.dataUsertHelper.getTypeUserAndAdmin();
  }

  async getModules() {
    const resp = await lastValueFrom(this.rolAbstraction.listModules());
    if (resp && resp.data && resp.data.length > 0) {
      this.listModulesTemp.update(() => this.componentHelper.moduleSidebar(resp.data[0].data));
    }
  }
  
  async getPermission() {
    const resp = await lastValueFrom(this.rolAbstraction.listModules());
    if (resp && resp.data && resp.data.length > 0) {
      this.listPermissionTemp.update(() => this.componentHelper.permission(resp.data[0].data));
    }
  }
}
